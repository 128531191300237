
import React from 'react'
import SectionBlock from '../../components/sectionBlock'


export default function ContactSection() {
    return (
        <section id="contactSection">
            <div className="section columnSection">
                <SectionBlock title="Adresy" text={["ul. Puławska 132a, Warszawa 02-620: od\u00A0godz.\u00A012.00\u00A0do\u00A020.00", "ul. Hrubieszowska 7, Warszawa 02-620: od\u00A0godz.\u00A011.00\u00A0do\u00A020.00"]} />
                <SectionBlock title="Kontakt" text={["ul. Puławska - Tel: (+48) 690 218 042", "ul. Hrubieszowska - Tel (+48) 451 255 750 ", "dozoveganramen@gmail.com"]} />
            </div>    
        </section>
    )
}
